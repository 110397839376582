<template>
<div>
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card>
            <div slot="header">
            <strong>Tipo de Insumo</strong>
          </div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th></th>
                <th>Descrição</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in mtp" :key="index">
                    <td>
                      <b-form-checkbox
                        v-model="mtp[index].mtp_active" 
                        switch
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input v-model="mtp[index].mtp_name"></b-form-input>
                    </td>
                    <td>
                        <button class="btn btn-warning" @click="editar(index)">Editar</button>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td><b-form-input v-model="mtpName"></b-form-input></td>
                    <td><button class="btn btn-success" @click="adicionar()">Adicionar</button></td>
                </tr>
            </tbody>
        </table>
          </b-card>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      materialItem: [],
      sessao: '',
      loading: false,
      mtp: [],
      mtpName: '',
      mtpActive: 1
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.listar('pro_Material_Tipo', 'mtp_ID', '').then(
          (response) => {
            this.mtp = response
            this.loading = false
          }, (response) => {})
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    adicionar: function () {
      if (this.mtpName === '') {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Tipo', 'mtp_ID', '(mtp_name = ' + this.mtpName + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.mtpName) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
          } else {
            this.$swal('Erro ao inserir: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.loading = true
          this.inserir('pro_Material_Tipo', {resource: [ {mtp_name: this.mtpName, mtp_active: 1} ]}).then(
            (response) => {
              this.mtpName = ''
              this.verificaSessao()
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    editar: function (index) {
      if (this.mtp[index].mtp_name === '') {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Material_Tipo', 'mtp_ID', '(mtp_name = ' + this.mtp[index].mtp_name + ') AND (mtp_ID != ' + this.mtp[index].mtp_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.mtpSigla) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Material_Tipo', {resource: [ {mtp_name: this.mtp[index].mtp_name, mtp_active: this.mtp[index].mtp_active} ]}, 'mtp_ID = ' + this.mtp[index].mtp_ID).then(
            (response) => {
              this.verificaSessao()
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>